@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@fortawesome/fontawesome-free/css/all.min.css";


@layer base {

    html {
        @apply font-roboflex font-normal not-italic;
        font-optical-sizing: auto;
    }

    body {
        @apply flex flex-col min-h-screen text-mackerel-400 dark:text-white bg-white dark:bg-mackerel-400 overflow-x-hidden;
    }

    a,
    button,
    input,
    select {
        &:focus-visible {
            @apply outline-mackerel-300 outline-[3px] outline-offset-4;
        }
    }
}

@layer components {
    .site-padding {
        @apply px-6 sm:px-10 md:px-28 lg:px-40;
    }

    .site-container {
        @apply max-w-[1512px] mx-auto;
    }

    .rich-text {
        @apply text-lg md:text-xl;

        h3 {
            @apply text-2xl md:text-3xl font-medium leading-[150%] pt-7 mb-8 md:mb-10;
        }

        p {
            @apply leading-7 mb-8 md:mb-10 last:mb-0;
        }

        a {
            @apply underline underline-offset-8 decoration-[1.5px] decoration-mackerel-200 hover:decoration-mackerel-300;

        }

        ol {
            @apply list-decimal list-outside ml-5;

            li {

                &:last-child {
                    @apply mb-5;
                }
            }
        }

        ul {
            @apply list-disc list-outside ml-5;

            li {
                &:last-child {
                    @apply mb-5;
                }
            }
        }
    }
    // Controls the animation of the menu toggle button (hamburger to cross icon tranisition)
    .button-menu-toggle {
        position: relative;
        display: block;
        width: 25px;
        height: 18px;
        background: transparent;
        border: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-out;
        margin: auto;

        &__line {
            @apply bg-mackerel-400 dark:bg-white;
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            transform: rotate(0deg);
            transition: top, transform, color, 0.25s ease-out;

            &:nth-child(1) {
                top: 2px;

                .mobile-menu-is-open & {
                    @apply bg-white;
                    transform: rotate(45deg);
                }
            }

            &:nth-child(2) {
                .mobile-menu-is-open & {
                    @apply bg-white;
                    top: 2px;
                    transform: rotate(-45deg);
                }
            }
        }
    }
    .header-logo {
        transition: color, 0.25s ease-out;

        &.mobile-menu-is-open {
            @apply text-white;
        }
    }
}
